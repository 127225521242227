.location_wrapper {
  width: 100%;
  /* padding: 20px 0 0 0; */
  position: relative;
  overflow-y: hidden;
  z-index: 10;
}

.position_box {
  padding: 0 50px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  height: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  background: rgba(40, 40, 40, 0.6);
  backdrop-filter: blur(5px);
  clip-path: polygon(0 0, 82% 0, 100% 100%, 0% 100%);
}

.position_boxs{
  display: none;
}
.from_wrapper {
  margin-right: 40px;
}
.form {
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 300px;
  padding: 20px;
}

.form > p{
  color: rgb(184, 5, 5);
}
.form_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: transparent;
}

.form input::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}

.form input {
  margin-bottom: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.input_img {
  margin-top: 5px;
}

@media only screen and (max-width: 1024px) {
  .position_box {
    display: none;
  }

  .position_boxs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px 0 0;
    width: 600px;
    height: 100%;
    background: rgba(40, 40, 40, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    padding: 0 20px 50px 20px;
  }

  .from_wrapper {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .form {
    width: 85%;
    margin: 0 auto;
  }
  .btn_wrap {
    display: none;
  }
}

.none_form {
  /* margin-bottom: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 640px) {
  .position_boxs {
    border-radius: 0;
  }
  .none_form {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 320px){
  .form_title{
    font-size: 28px;
  }
} 
