.links > p {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Rubik";
}

.links {
  position: relative;
}

/* .header_logo{
  width: 100px;
  object-fit: cover;
} */

.active > p {
  border-bottom: 4px solid #fff !important;
  
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Rubik";
}

.links::before {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  margin: 0 auto;
  height: 2px;
  background-color: #fff;
  bottom: -5px;
  border-radius: 50px;
  transition: 0.9s transform;
  transform: scaleX(0);
  transform-origin: right;
}
.links:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

/* .active > p::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    margin: 0 auto;
    height: 2px;
    background-color: #fff;
    bottom: -5px;
    border-radius: 50px;
    transition: 0.9s transform;
  } */

.category_wrapper {
  z-index: 1;
  background: #ffffff;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #eeeeee;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 300px;
  padding: 0 30px 10px 30px;
  position: -webkit-fixed;
  position: fixed;
  top: 180px;
  left: 20px;
}
.category_dropdown {
  display: none;
}
.category_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
}
.category_title > i {
  margin-right: 7px;
  font-size: 25px;
  color: #03544c;
}
.category_list {
  width: 100%;
  padding: 0;
  list-style: none;
}
.category_list > li {
  margin-bottom: 20px;
}

.drop_link {
  text-decoration: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
}
.drop_link > img {
  margin-right: 10px;
}

.category_dropdown > .ant-dropdown-menu {
  padding: 10px;
}

@media only screen and (max-width: 1302px) {
  .category_wrapper {
    width: 270px;
  }
}
@media only screen and (max-width: 1222px) {
  .category_wrapper {
    width: 250px;
  }
}
@media only screen and (max-width: 1155px) {
  .category_wrapper {
    width: 230px;
  }
}

@media only screen and (max-width: 1090px) {
  .category_wrapper {
    display: none;
  }
  .category_dropdown {
    display: inline-block !important;
    border-radius: 10px !important;
    background: #03544c;

  }
}

@media only screen and (max-width: 425px) {
  .header_logo{
    width: 110px;
    object-fit: cover;
  }
}
